import { default as DefaultHeader } from '../../components/Header';

function Header() {
    return (
        <DefaultHeader 
            title='Командные роли'
            text='В каждом из семи блоков данного опросника распределите 10 баллов между возможными ответами согласно тому, как вы полагаете они лучше всего подходят вашему собственному поведению. Если вы согласны с каким-либо утверждением на все 100%, вы можете отдать ему все 10 баллов. При этом одному предложению можно присвоить минимум 2 балла. Проверьте, чтобы сумма всех баллов по каждому блоку не превышала 10.'
        />
    );
}

export default Header;
