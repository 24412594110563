import { default as DefaultHeader } from '../../components/Header';

function Header() {
    return (
        <DefaultHeader 
            title='Роли менеджера'
            text='Вам предлагается определить предпочитаемые управленческие роли, оценив
                приведенные ниже утверждения. Все
                утверждения объединены в 8 тематических блоков.
                Рекомендуется следующий порядок действий: прочите подряд четыре утверждения, затем распределите между ними
                100
                баллов. Утверждению в наибольшей степени соответствующему вашему мнению вы присваиваете большее количество
                баллов, минимальный балл получит утверждение в меньшей степени соответствующее вашей позиции. В блоке
                следует
                оценить каждое утверждение. Сумма баллов в каждом блоке должна составлять 100. Например: 45+15+30+10= 100.
                Баллы
                запишите в клетках слева от утверждений. Постарайтесь быть объективным в своих оценках.'
        />
    );
}

export default Header;
